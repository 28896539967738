.container {
    padding: 50px;
}
li {
    padding-top: 10px;
    padding-bottom: 10px;
}
@media all and (max-width: 600px)
{
    body {
       font-size: 150%;
    }
    h3 {
        font-size: 200%;
    }
    h5 {
        font-size: 150%;
    }
    .horizontal-scroll {
        overflow-x: scroll;
    }
    .container {
        padding: 10px;
    }
}
.commentary {
    display: inline-block;
    font-family: serif;
    font-size: 70%;
    margin: 0 10% 0 10%;
    text-align: justify;
}
.imperative {
    color: blue;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.quick-nav {
    background-color: #fafafa;

    padding: 6px;
    margin-bottom: 10px;
}
.quick-nav a {
    background-color: #eee;
    padding: 5px;
    display: inline-block;
    color: #333;
    font-size: 80%;
    text-transform: uppercase;
    margin: 0 3px 3px 0;
    text-align: center;
}
.quick-nav a:hover {
    background-color: #ffffe6;
}
.quick-nav a.book,
.quick-nav a.chapter {
    width: 3em;
}
.subjunctive {
    color: green;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.verse-number {
    color: #1a1a1a;
}
.verse a {
    color: #1a1a1a;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.verse a:hover {
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffe6;
}
