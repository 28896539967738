.container {
    padding: 50px;
    max-width: 750px;
}
li {
    padding-top: 10px;
    padding-bottom: 10px;
}
.tightList {
    padding-top: 0px;
    padding-bottom: 0px;
}

textarea {
    width: 100%;
    height: 150px;
    max-width: 50em;
    white-space: pre-wrap;
    line-height: 1.5;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
}

h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 10px;
}
@media all and (max-width: 600px)
{
    body {
       font-size: 125%;
    }
    h3 {
        font-size: 125%;
    }
    h5 {
        font-size: 125%;
    }
    .horizontal-scroll {
        overflow-x: scroll;
    }
    .container {
        padding: 10px;
    }
}
.connector {
    color: red;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.imperative {
    color: blue;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.strongs {
    font-size: 50%;
    color: grey;
}
.subjunctive {
    color: green;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.usage {
    padding-bottom: 10px;
}
.verse-number {
    color: #1a1a1a;
}
.verse a {
    color: #1a1a1a;
    text-decoration: none;
    border-bottom: 1px dotted #e6e6e6;
}
.verse a:hover {
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffe6;
}
.quick-nav-item {
    background-color: #eee;
    padding: 5px;
    display: inline-block;
    color: #333;
    font-size: 50%;
    text-transform: uppercase;
    margin: 0 3px 3px 0;
    text-align: center;
}
.quick-nav {
    background-color: #fafafa;
    padding: 6px;
    margin-bottom: 10px;
}
.quick-nav a {
    background-color: #eee;
    padding: 5px;
    display: inline-block;
    color: #333;
    font-size: 50%;
    text-transform: uppercase;
    margin: 0 3px 3px 0;
    text-align: center;
}
.quick-nav a:hover {
    background-color: #ffffe6;
}
.quick-nav a.book,
.quick-nav a.chapter {
    width: 3em;
}